<script lang="ts" setup>
const { fetchFeaturedMaps } = useMaps()
const { formatedImageUrl } = useStorage()
const { data: featuredMaps } = await useAsyncData('featured-maps', fetchFeaturedMaps)
const videoSource = '/media/videos/home-video-hero.webm'
</script>

<template>
  <section>
    <div class="hero-wrapper">
      <div class="video-wrapper">
        <video playsinline autoplay muted loop poster="/media/videos/6-poster.jpg">
          <source :src="videoSource" type="video/webm">
        </video>
      </div>
      <MPTitleRolodex
        :words="['Adventure', 'Spot', 'Restaurant', 'Experience', 'Hotel', 'Attraction', 'Excursion', 'Journey']"
        class="content"
        :first-line="false"
      >
        <template #title>
          <span class="first-title">Discover your next</span>
        </template>
        <template #titleContinued>
          <span class="second-title">favorite</span>&nbsp;
        </template>
        <template #summary>
          <span class="summary-text">Explore curated lists from local experts and global creators. <span>Discover and support the people bringing the best of every place to you.</span></span>
        </template>
        <template #buttons>
          <NuxtLinkLocale to="/explore" class="hero-cta-buttons">
            <MPButton label="Explore" version="primary" rounded size="large"/>
          </NuxtLinkLocale>
          <NuxtLinkLocale to="/creators" class="hero-cta-buttons">
            <MPButton label="" link rounded size="large">
              Become a Creator&nbsp;<i class="ri-arrow-right-box-line"/>
            </MPButton>
          </NuxtLinkLocale>
        </template>
      </MPTitleRolodex>
      <!-- <MPButton label="Explore Map" version="primary"/>
      <MPButton label="Become a creator" version="default" text/> -->
      <MPSkewContainer
        id="map-skew"
        image-src="/media/images/map-bcn-1x.jpg"
        class="map skew-grahic"
        animate
      />
      <MPSkewContainer
        id="outline-skew"
        stroke="var(--color-rose-400)"
        class="outline skew-grahic"
      />
      <MPSkewContainer
        id="image-skew"
        :video-src="videoSource"
        class="fill skew-grahic"
      />
    </div>
    <div class="hero-callout">
      <h2>
        For those seeking deeper travel; authentic insights from passionate local experts
      </h2>
    </div>
    <div v-if="featuredMaps" ref="carousel" class="carousel">
      <NuxtLinkLocale
        v-for="map in featuredMaps"
        :key="map.id"
        :to="`/maps/${map.id}`"
        :style="`background-image: url('${formatedImageUrl(map.cover_image_url, 350, 400, 80)}')`"
        class="carousel-item"
      >
        <span class="carousel-item-title">
          {{ map.title }}
        </span>
        <span class="carousel-item-spots">
          {{ map.spots_count }} spots
        </span>
      </NuxtLinkLocale>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
@import "@/assets/css/breakpoints.pcss";
@import "@/assets/css/typography.pcss";
@import "@/assets/css/layout.pcss";

.second-title {
  display: none;
  @mixin screen sm {
    display: inline-flex;
    margin-left: 0;
  }
}

.video-wrapper {
  background-color: var(--color-black);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  @mixin screen sm {
    display: none;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.80) 0.16%, rgba(0, 0, 0, 0.20) 38.16%, rgba(0, 0, 0, 0.85) 67.37%);
  }
}

.hero-cta-buttons {
  display: grid;
  gap: 0.5rem;
  @mixin screen sm {
    display: flex;
  }
  :deep(button.link) {
    color: white !important;
  }
  :deep(button.link) {
    @mixin screen sm {
      color: black !important;
    }
  }
  @mixin screen sm {
    display: inline-flex;
  }
}

.hero-wrapper {
  @mixin layout-wrapper-wide;
  margin: 0 auto;
  position: relative;
  display: grid;
  z-index: 1;
  position: relative;
  height: 101dvh;
  min-height: 35rem;
  /* max-height: 40rem; */
  align-items: end;
  transition: height 0.5s ease-in;
  transition-delay: 0.25s;
  article {
    height: 100%;
    justify-content: end;
  }
  @mixin screen sm {
    article {
      height: 100%;
      justify-content: center;
    }
    height: 50rem;
    background: transparent;
    margin: 2vh auto;
    align-items: center;
  }
  @mixin screen xxl {
    margin: 4vh auto 6vh;
  }
}

.hero-callout {
  padding: 6rem 1rem 5rem;
  text-align: center;
  h2 {
    line-height: 1.45;
  }
  @mixin screen sm {
    display:none;
  }
}

.map,
.fill-mobile {
  z-index: 0;
  position: absolute;
  overflow: hidden;
  height: auto;
  opacity: 0.5;
  top: -2rem;
  right: -6rem;
  width: 270px;
  @mixin screen xs {
    right: -2rem;
    top: 0;
    width: 440px;
  }
}

.map {
  display: none;
  @mixin screen xs {
    display: block;
  }
}

.fill-mobile {
  display: block;
  @mixin screen xs {
    display: none;
  }
}

.outline {
  z-index: 0;
  position: absolute;
  overflow: hidden;
  top: 2rem;
  right: -1rem;
  stroke-width: 1rem;
  width: 250px;
  @mixin screen xs {
    top: 5rem;
    right: 10rem;
    width: 330px;
  }
}

.fill {
  display: none;
  @mixin screen xs {
    display: block;
    position: absolute;
    top: 12rem;
    right: 5rem;
  }
}

.content {
  padding: 1rem 1rem 0;
  animation: fade-in 0.25s ease-in-out forwards;
  z-index: 10;
  margin: 0 auto;
  height: fit-content;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  @mixin screen sm {
    all: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
    text-align: left;
    height: auto;
    background: linear-gradient(120deg, #FFF 35%, rgba(255, 255, 255, 0.00) 50%);
  }
  @mixin screen md {
    background: transparent;
  }
}

.summary-text {
  span {
    display: none;
    @mixin screen sm {
      display: inline;
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.carousel {
  width: 100%;
  display: inline-flex;
  gap: 1rem;
  overflow-x:scroll;
  padding-left: 1rem;
  scrollbar-width: none;
  margin-top: 0;
  z-index: 1;
  position: relative;
  a:hover {
    box-shadow: var(--shadow-lg);
    transform: translateY(-0.25rem);
    transition: all 0.2s ease-in-out;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.carousel-item {
  min-width: 15rem;
  min-height: 20rem;
  background-size: cover;
  background-position: center center;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: end;
  color: white;
  padding: 1rem;
  gap: 0.5rem;
  position: relative;
  overflow: hidden;
  box-shadow: 0;
  transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: var(--shadow-lg);
    transform: translateY(-0.15rem);
    transition: all 0.2s ease-in-out;
  }
  @mixin screen xs {
    min-width: 18rem;
    min-height: 22rem;
  }
}

.carousel-item:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 10%, rgba(0, 0, 0, 0.80) 100%);
  border-radius: 1rem;
}

.carousel-item-title {
  @mixin typeface-summary;
  font-weight: 400;
  z-index: 1;
  margin-bottom: 1rem;
}

.carousel-item-spots {
  @include typeface-detail;
  z-index: 1;
  width: fit-content;
  border-radius: 2rem;
  border: 1px solid var(--color-slate-100);
  background-color: rgba(0,0,0,0.5);
  padding: 0.25rem 1rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
    transform: translateX(0);
  }
}

.carousel-item {
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
}

.carousel-item:nth-child(1) { animation-delay: .2s; }
.carousel-item:nth-child(2) { animation-delay: .3s; }
.carousel-item:nth-child(3) { animation-delay: .4s; }
.carousel-item:nth-child(4) { animation-delay: .5s; }
.carousel-item:nth-child(5) { animation-delay: .6s; }
.carousel-item:nth-child(6) { animation-delay: .7s; }
.carousel-item:nth-child(7) { animation-delay: .8s; }
.carousel-item:nth-child(8) { animation-delay: .9s; }
.carousel-item:nth-child(9) { animation-delay: 1s; }
.carousel-item:nth-child(10) { animation-delay: 1.1s; }
.carousel-item:nth-child(11) { animation-delay: 1.2s; }

.featured-creators {
  text-align:center;
}

.skew-grahic {
  display: none;
  @mixin screen sm {
    display: block;
  }
}
</style>
